/*eslint no-extend-native: ["error", { "exceptions": ["String"] }]*/
String.prototype.toCurrency = function(decimals) {

    const number = parseFloat(this);

    if(number.toString() !== 'NaN'){
      if(number < 0) {
        return '($ ' + number.toFixed(decimals).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') +')';
      } else {
        return '$ ' + number.toFixed(decimals).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      }
    } else {
      return;
    }
  }