import { Box } from '@mui/material';
import React from 'react'
import { useNavigate } from 'react-router-dom';

import './ProductsView.css';

export const products = {
  tempolife: {
    name: 'TempoLife',
    description: 'Seguro de vida en el que podrás elegir el tiempo de protección: 5, 10, 15, 20 o hasta los 65 años.',
    images: {
      life: 'https://playtivities.com/wp-content/uploads/2017/10/1-living-room-games.jpg'
    }
  },
  metalife: {
    name: 'MetaLife',
    description: '¡Seguro de vida con ahorro garantizado a largo plazo! Experimenta todo el apoyo para conseguir tus más grandes metas.',
    images: {
      life: 'https://playtivities.com/wp-content/uploads/2017/10/1-living-room-games.jpg',
      investmentSavings: 'https://playtivities.com/wp-content/uploads/2017/10/1-living-room-games.jpg',
      retirement: 'https://sqy7rm.media.zestyio.com/how-much-do-i-need-for-retirement.webp',
    }
  },
  totalife: {
    name: 'TotaLife',
    description: 'La protección está en tus manos, tú decides el tiempo en el que estarás protegido y al mismo tiempo estarás ahorrando.',
    images: {
      life: 'https://www.nm.org//-/media/northwestern/healthbeat/images/wellness/nm-how-happiness-affects-health-tnail.jpg'
    }
  },
  perfectlife: {
    name: 'PerfectLife',
    description: 'Protégete el tiempo que tú decidas, con un seguro de vida de 5, 10, 15, 20 o hasta los 65 años. ¡Ahorra en dólares o en pesos!',
    images: {
      investmentSavings: 'https://static.scientificamerican.com/sciam/cache/file/AD507544-7345-4983-BB20A906BDDF813F_source.jpg'
    }
  },
  educalife: {
    name: 'EducaLife',
    description: 'Aseguremos el mañana, plan de ahorro para los estudios, metas y sueños por cumplir de tus hijos.',
    images: {
      investmentSavings: 'https://www.hopkinsmedicine.org/-/media/images/health/3_-wellness/babies-and-toddlers-health/how-to-get-your-child-ready-school-hero.ashx?h=500&iar=0&mh=500&mw=1300&w=1297&hash=68F774FC02120666935506687569020C'
    }
  },
  flexilifeInvestment: {
    name: 'FlexiLife Inversión',
    description: 'some description here will be very cool',
    images: {
      investmentSavings: 'https://playtivities.com/wp-content/uploads/2017/10/1-living-room-games.jpg'
    }
  },
  flexilifeProtection: {
    name: 'FlexiLife Protección',
    description: 'some description here will be very cool',
    images: {
      investmentSavings: 'https://playtivities.com/wp-content/uploads/2017/10/1-living-room-games.jpg'
    }
  },
  flexilifeDreams: {
    name: 'FlexiLife Sueños',
    description: 'some description here will be very cool',
    images: {
      investmentSavings: 'https://playtivities.com/wp-content/uploads/2017/10/1-living-room-games.jpg'
    }
  },
  medicalifeFamiliar: {
    name: 'MedicaLife Familiar',
    description: 'Protege a tu familia con la seguridad de acceder a los hospitales y médicos que tú decidas. Explora nuestros planes.',
    images: {
      medical: 'https://images.squarespace-cdn.com/content/v1/58ee6d5f6a4963e4295ee110/1505354519619-PFPBJYP04XFZZ8L1XQY9/family+doctor.jpg?format=1000w'
    }
  },
  medicalifePrimordial: {
    name: 'MedicaLife Primordial',
    description: 'Siéntete seguro en caso de padecer una enfermedad, así como un apoyo económico en caso de accidente u hospitalización.',
    images: {
      medical: 'https://media.istockphoto.com/id/614429840/photo/young-boy-with-broken-arm.jpg?s=612x612&w=0&k=20&c=dVcEZpPFzglhGNSE6PtckyLqJBjKVpjfnJ3hHy_NdGA='
    }
  },
  protection: {
    name: 'Protección Garantizada',
    description: '¡Puedes ampliar tu Seguro de Gastos Médicos que te da tu empresa! Protegiéndote incluso si dejas tu trabajo.',
    images: {
      medical: 'https://images.squarespace-cdn.com/content/v1/54d50ceee4b05797b34869cf/1454945947786-5PDXLHLW8IHT8DWPON9F/image-asset.jpeg?format=500w'
    }
  },
  horizon: {
    name: 'Horizonte',
    description: '¡Seguro de vida con el que podrás ahorrar! Recibe tu ahorro a la edad de tu retiro en un solo pago o mensualidades, tú eliges.',
    images: {
      retirement: 'https://www.hrblock.com/expat-tax-preparation/resource-center/wp-content/uploads/2022/10/retire-abroad-taxes.jpeg'
    }
  },
}

const productCategories = {
  life: {
    name: 'Vida',
    description: 'Cada familia es un equipo, y liderar un equipo es una responsabilidad que no tienes que cargar por tu cuenta. Un seguro de vida como complemento a tu ahorro te permite enfocarte en lo esencial: disfrutar a tu familia y cumplir metas juntos mientras tienen respaldo financiero.',
    children: [[products.tempolife, products.metalife, products.totalife]],
  },
  investmentSavings: {
    name: 'Inversión y Ahorros',
    description: '¿Qué te gustaría lograr en los próximos 5, 10, o 20 años? Si comienzas a ahorrar desde ahora con la ayuda de un seguro de vida, un panorama de posibilidades se abre frente a ti. Invierte en tu futuro hogar, en viajes por el mundo o en la educación de tus hijos.',
    children: [
      [
        products.metalife, 
        products.perfectlife, 
        products.educalife, 
      ],
      [
        products.flexilifeInvestment, 
        products.flexilifeDreams, 
        products.flexilifeProtection
      ]
    ],
  },
  medical: {
    name: 'Gastos Médicos y Accidentes',
    description: 'Tu salud y la de tu familia siempre será lo más importante, comienza dando el primer paso y descubriendo cómo se adapta a ti cada uno de nuestros planes.',
    children: [[products.medicalifeFamiliar, products.medicalifePrimordial, products.protection]]
  },
  retirement: {
    name: 'Retiro',
    description: 'Juntos podemos hacer que tu retiro esté lleno de tranquilidad y disfrute. Dedica tu vida a lo que más importa y sin ninguna preocupación. Permanece protegido con un seguro de vida mientras ahorras para lograr un retiro soñado.',
    children: [[products.horizon, products.metalife]]
  },
}

function ProductsView() {


  const navigate = useNavigate();

  const handleClickOnProduct = (product) => {
    navigate(''+product);
  }

  return (
    <div className='products-view'>
      <div className='products-banner'>
        <img src='https://imagesvc.meredithcorp.io/v3/mm/image?url=https%3A%2F%2Fstatic.onecms.io%2Fwp-content%2Fuploads%2Fsites%2F34%2F2021%2F05%2F27%2Fmother-and-daughter-dancing-living-room-getty-0521-2000.jpg' alt='' />
        <h1>Tú y tu familia, siempre estará tranquila</h1>
        <p>Nuestra meta, es la protección de ti y todos tus seres queridos</p>
      </div>
      <Box className='cofinza-gradient' style={{width: '100vw', height: '10px'}}/>
      <Box className='products-grid'>
        <h1>Logremos la protección de ti y tu familia con el mejor plan.</h1>
        <p>
          Siempre estás en busca de metas cada vez más grandes, porque ese es tu propósito, lograrlo todo.
          <br/>
          <br/>
          Tener una gran protección es el mejor plan en cualquier camino, cuidando tus pasos hoy para que vayas seguro hacia tu meta del mañana.
          <br/>
          <br/>
          ¿Cuál será tu próximo objetivo?
        </p>
        {
          Object.entries(productCategories).map(([key, category]) => (
            <Box key={key} className='product-category'>
              <h2>{category.name}</h2>
              <Box className='cofinza-gradient' style={{width: '100px'}} />
              <p>{category.description}</p>
              {
                category.children.map((row, index) => (
                  <Box key={index} className='category-row'>
                    {
                      row.map(product => (
                        <Box key={product.name} className='product-card' onClick={() => handleClickOnProduct(product.name)}>
                          <img src={product.images[key]} alt=''/>
                          <Box className='cofinza-gradient' />
                          <Box className='product-card-body'>
                            <h3>{product.name}</h3>
                            <p>{product.description}</p>
                          </Box>
                        </Box>
                      ))
                    }
                  </Box>
                ))
              }
            </Box>
          ))
        }
      </Box>
    </div>
  )
}

export default ProductsView