import React from 'react'

function FAQView() {
  return (
    <div className='faq-view'>
        <div className='banner'>
            <h1>FAQ</h1>
            <h2>Frequently Asked Questions</h2>
            <p>Respuestas a nuestras preguntas más cotizadas por clientes o asegurados.</p>
        </div>
    </div>
  )
}

export default FAQView;