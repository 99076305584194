import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react'

import './Carrousel.css';

function Carrousel(props) {

    const childrenLength = props.children.length;


    const [activeIndex, setActiveIndex] = useState(0);

    const updateIndex = (newIndex) => {
        if(newIndex < 0) {
            newIndex = childrenLength - 1;
        } else if(newIndex >= childrenLength){
            newIndex = 0;
        }
        setActiveIndex(newIndex);
    }

    useEffect(() => {
      const interval = setInterval(() => {
        updateIndex(activeIndex + 1);
      }, 2500);
    
      return () => {
        if(interval){
            clearInterval(interval);
        }
      }
    });
    
    return (
        <Box className='carrousel'>
            <Box className='inner-carrousel' sx={{width: (100 * childrenLength) + '%', left: `${-100 * activeIndex}%`}}>
                {
                    props.children.map((child, index) => (
                        <Box className='carrousel-child' key={index}>
                            {child}
                        </Box>
                    ))
                }
            </Box>
        </Box>
    );
}

export default Carrousel