export function getColor(string){
    return getComputedStyle(document.body).getPropertyValue(string);
}

export function addColor(colorA, colorB, amount, alpha = 1) {

    const colorA2 = colorA.replace('rgb(', '').replace('rgba(', '').slice(0, colorA.length - 5).split(', ').map(numb => parseFloat(numb));
    const colorB2 = colorB.replace('rgb(', '').replace('rgba(', '').slice(0, colorB.length - 5).split(', ').map(numb => parseFloat(numb));

    const porcentage = amount.clamp(0, 1);

    const r1 = (colorA2[0] * (1 - porcentage));
    const r2 = (colorB2[0] * porcentage);

    const g1 = (colorA2[1] * (1 - porcentage));
    const g2 = (colorB2[1] * porcentage);

    const b1 = (colorA2[2] * (1 - porcentage));
    const b2 = (colorB2[2] * porcentage);

    const r = r1 + r2;
    const g = g1 + g2;
    const b = b1 + b2;
    
    const rgb = [Math.ceil(r), Math.ceil(g), Math.ceil(b)];
    
    const newColor = `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${alpha})`;

    return newColor;

}