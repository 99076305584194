import { Box, Button, Paper, Step, StepContent, Stepper, Table, TableRow, TableBody, TableHead, TextField, Typography, TableContainer, MenuItem, IconButton, StepButton, InputAdornment } from '@mui/material';
import { useState } from 'react'
import {addColor, getColor} from './../../utils/colors';
import TableCell from '@mui/material/TableCell';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';

import './SevenQuestionsView.css';
import { Delete } from '@mui/icons-material';
import SevenQuestionsQuotationGrid from './SevenQuestionsQuotationGrid';
import { postToBack } from '../../utils/back';


const questions = [
    {
        question: '¿Cuánto ingreso mensual te gustaria proveer, en caso de fallecer?',
        description: 'Piense cuánto dinero necesitará su familia para cubrir los gastos de la vida diaria. No incluya los ahorros para la universidad, el cuidado de niños o cualquier deuda que le gustaría pagar de inmediato (p. ej. hipoteca), ya que se tratan en otras preguntas.',
        color: addColor(getColor('--question-one'), getColor('--question-seven'), 0/6),
    },
    {
        question: '¿Cuántos años de ingresos te gustaría proveer?',
        description: 'Piense por cuánto tiempo necesitará los ingresos adicionales para mantenerse su familia. Si tiene hijos, eso podría ser cuando su hijo menor se gradúe de la universidad.',
        color: addColor(getColor('--question-one'), getColor('--question-seven'), 1/6),
    },
    {
        question: '¿Cuál es el monto de tu deuda actual?',
        description: 'Considere cosas como hipotecas pendientes, préstamos estudiantiles privados, saldos de tarjetas de crédito y préstamos para automóviles.',
        color: addColor(getColor('--question-one'), getColor('--question-seven'), 2/6),
    },
    {
        question: '¿Cuánto tienes ahorrado actualmente?',
        description: 'Considere los ahorros para universidad, compra de propiedad o retiro.',
        color: addColor(getColor('--question-one'), getColor('--question-seven'), 3/6),
    },
    {
        question: '¿Cuántos hijos necesitan pago universitario?',
        description: 'Agrege niños a los que les gustaría proporcionar fondos para la universidad o haga click en Siguiente para omitir este paso.',
        color: addColor(getColor('--question-one'), getColor('--question-seven'), 4/6),
    },
    {
        question: '¿Cuánto te gustaría dejar de fondo de emergencia?',
        description: 'Si aún no tiene uno, es una buena idea reservar por lo menos tres a seis meses de gastos en su fondo de emergencia.',
        color: addColor(getColor('--question-one'), getColor('--question-seven'), 5/6),
    },
    {
        question: '¿Qué monto de suma asegurada tienes actualmente?',
        description: 'No incluya ninguna póliza de seguro de vida que pueda tener a través del trabajo, ya que es probable que estas pólizas se eliminen cuando cambie de trabajo.',
        color: addColor(getColor('--question-one'), getColor('--question-seven'), 6/6),
    },
]


function SevenQuestionsView() {

    const [state, setState] = useState(0);
    const [answers, setAnswers] = useState({
        0: {value:'', placeholder: '60,000.00'},
        1: {value:'', placeholder: '0'},
        2: {value:'', placeholder: '0'},
        3: {value:'', placeholder: '0'},
        4: {children:[]},
        5: {value:'', placeholder: '100,000.00'},
        6: {value:'', placeholder: '1,000,000.00'},
    });

    const [completed, setCompleted] = useState([
        false,
        false,
        false,
        false,
        false,
        false,
        false,
    ]);

    const [errorTexts, setErrorTexts] = useState([
        null,
        null,
        null,
        null,
        null,
        null,
        null,
    ]);

    const handleNext = () => {
        var isValid = false;
        switch(state){
            case 4: isValid = true;
            break;

            default: isValid = answers[state].value !== '';
            break;
        }
        if(isValid){

            if(state === 6) setCanSubmit(true);

            const c = completed;
            c[state] = true;
            setCompleted(c);

            const newErrors = [...errorTexts];
            newErrors[state] = null;
            setErrorTexts(newErrors);

            setState(state + 1);
        } else {
            const c = completed;
            c[state] = false;
            setCompleted(c);

            const newErrors = [...errorTexts];
            newErrors[state] = 'No puede estar vacio';
            setErrorTexts(newErrors);
        }
    };

    const [canSubmit, setCanSubmit] = useState(false);

    const verifyErrors = () => {
        const newErrors = [...errorTexts];
        var hasErrors = false;
        var firstError = null;
        newErrors.forEach((error, index) => {
            var isValid = false;
            switch(index){
                case 4: isValid = true;
                break;
    
                default: isValid = answers[index].value !== '';
                break;
            }

            if(isValid) newErrors[index] = null;
            else {
                if(firstError === null) setState(index);
                hasErrors = true;
                newErrors[index] = 'No puede estar vacio'
            };
        });

        setErrorTexts(newErrors);

        return hasErrors;
    }

    const handleBack = () => setState(state - 1);
    const handleSendEmail = async (data) => {
        const response = await postToBack('/SevenQuestions/email', {values: data});
        return response;
    }
    const handleRequestQuotation = async (data) => {
        const response = await postToBack('/SevenQuestions/quotation', {values: data});
        return response;
    }

    const handleReset = () => {
        setState(0);
        setAnswers({
            0: {value:'', placeholder: '60,000.00'},
            1: {value:'', placeholder: '0'},
            2: {value:'', placeholder: '0'},
            3: {value:'', placeholder: '0'},
            4: {children:[]},
            5: {value:'', placeholder: '100,000.00'},
            6: {value:'', placeholder: '1,000,000.00'},
        });

        setCompleted([
            false,
            false,
            false,
            false,
            false,
            false,
            false,
        ]);

        setErrorTexts([
            null,
            null,
            null,
            null,
            null,
            null,
            null,
        ]);
        
        setCanSubmit(false);
        setSubmitted(false);
        setSubmittedData({});
    };

    const handleSubmit = async () => {
        if(verifyErrors()){

        } else {
            setState(questions.length);
            try{
                const response = await postToBack('/SevenQuestions/', {values: answers});
                if(response.status === 200) {
                    const data = await response.json();
                    setSubmitted(true);
                    setSubmittedData(data);
                } else {
                    console.log(await response.text());
                }
            } catch (err) {
                console.log(err);
            }
        }
    }

    const handleStepClick = (index) => {
        setSubmitted(false);
        if(state >= questions.length) setState(index);
        else {
            var isValid = false;
            switch(state){
                case 4: isValid = true;
                break;
    
                default: isValid = answers[state].value !== '';
                break;
            }
            if(isValid) {
                const c = completed;
                c[state] = true;
                setCompleted(c);
            }
            setState(index);
        }
    };

    const handleAddNewChild = () => {
        const children = [...answers[4].children];
        children.push({age: 1, schoolType: 'privada'});

        setAnswers({...answers, 4: {...answers[4], children: children}});
    }

    const handleDeleteChild = (index) => {
        const children = [...answers[4].children];
        children.splice(index, 1);
        setAnswers({...answers, 4: {...answers[4], children: children}});
    }

    const handleUpdateChild = (key, value, index) => {

        if(key === 'age'){
            if(parseInt(value) < 0 || parseInt(value) > 18){
                return;
            }        
        }

        const children =[...answers[4].children];
        const child = children[index];
        child[key] = value;
        children.splice(index, 1, child);

        setAnswers({...answers, 4: {...answers[4], children: [...children]}});
    }

    const [submitted, setSubmitted] = useState(false);
    const [submittedData, setSubmittedData] = useState({});
    
    const color = state < questions.length ? questions[state].color : questions[1].color;
    // const headerColor = addColor(color, 'rgb(0, 0, 0)', 0.2);

    return (
        <div className='seven-questions-view' style={{backgroundColor: color}}>
            <div className='background-gradient'/>
            <div className='seven-header'>
                <h1>Seven Questions</h1>
                <p>Un sistema de 7 Preguntas dónde podras saber tu suma asegurada ideal para un seguro de vida. Al finalizar podras enviar tu respuesta a tu correo o solicitar una cotización con uno de nuestros agentes.</p>
            </div>
            <Paper className='seven-body'>
                <div className='seven-questions-stepper'>
                    <Stepper activeStep={state} orientation='vertical' nonLinear>
                        <Step completed={completed[0]}>
                            <CustomStepLabel handleClick={handleStepClick} question={questions[0]} index={0} completed={completed} answers={answers} isActive={state === 0}/>
                            <CustomStepContent question={questions[0]} index={0} handleNext={handleNext} handleBack={handleBack}>
                                <CustomMoneyStepTextField index={0} answers={answers} onChange={setAnswers} {...answers[0]} onKeyPress={handleNext} helperText={errorTexts[0]}/>
                            </CustomStepContent>
                        </Step>
                        <Step completed={completed[1]} disabled={!completed[0]}>
                            <CustomStepLabel handleClick={handleStepClick} question={questions[1]} index={1} completed={completed} answers={answers} isActive={state === 1}/>
                            <CustomStepContent question={questions[1]} handleNext={handleNext} handleBack={handleBack}>
                                <CustomTextField index={1} answers={answers} onChange={setAnswers} type='tel' {...answers[1]}
                                    InputProps={{startAdornment: <InputAdornment position='start'>Años:</InputAdornment>}}
                                    onKeyPress={handleNext} helperText={errorTexts[1]}
                                />
                            </CustomStepContent>
                        </Step>
                        <Step completed={completed[2]} disabled={!completed[1]}>
                            <CustomStepLabel handleClick={handleStepClick} question={questions[2]} index={2} completed={completed} answers={answers} isActive={state === 2}/>
                            <CustomStepContent question={questions[2]} handleNext={handleNext} handleBack={handleBack}>
                                <CustomMoneyStepTextField index={2} answers={answers} onChange={setAnswers} {...answers[2]} onKeyPress={handleNext} helperText={errorTexts[2]}/>
                            </CustomStepContent>
                        </Step>
                        <Step completed={completed[3]} disabled={!completed[2]}>
                            <CustomStepLabel handleClick={handleStepClick} question={questions[3]} index={3} completed={completed} answers={answers} isActive={state === 3}/>
                            <CustomStepContent question={questions[3]} handleNext={handleNext} handleBack={handleBack}>
                                <CustomMoneyStepTextField index={3} answers={answers} onChange={setAnswers} {...answers[3]} onKeyPress={handleNext} helperText={errorTexts[3]}/>
                            </CustomStepContent>
                        </Step>
                        <Step completed={completed[4]} disabled={!completed[3]}>
                            <CustomStepLabel handleClick={handleStepClick} question={questions[4]} index={4} completed={completed} answers={answers} isActive={state === 4}/>
                            <CustomStepContent question={questions[4]} handleNext={handleNext} handleBack={handleBack}>
                                <Box>
                                    <TableContainer component={Paper} sx={{backgroundColor: 'transparent'}} className='children-table'>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell># de hijo</TableCell>
                                                    <TableCell>edad</TableCell>
                                                    <TableCell>tipo de escuela</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {
                                                    answers[4].children.length > 0 
                                                    ? answers[4].children.map((child, index) => (
                                                        <ChildRow 
                                                            key={index} 
                                                            child={child} 
                                                            updateChild={handleUpdateChild} 
                                                            index={index} 
                                                            deleteChild={handleDeleteChild}
                                                        />
                                                    ))
                                                    : <TableRow><TableCell sx={{textAlign: 'center'}} colSpan={4}>No hay hijos agregados</TableCell></TableRow>
                                                }
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Button className='new-child-btn' variant='contained' onClick={handleAddNewChild}>
                                        agregar nuevo hijo(a)
                                    </Button>
                                </Box>
                            </CustomStepContent>
                        </Step>
                        <Step completed={completed[5]} disabled={!completed[4]}>
                            <CustomStepLabel handleClick={handleStepClick} question={questions[5]} index={5} completed={completed} answers={answers} isActive={state === 5}/>
                            <CustomStepContent question={questions[5]} handleNext={handleNext} handleBack={handleBack}>
                                <CustomMoneyStepTextField index={5} answers={answers} onChange={setAnswers} {...answers[5]} onKeyPress={handleNext} helperText={errorTexts[5]}/>
                            </CustomStepContent>
                        </Step>
                        <Step completed={completed[6]} disabled={!completed[5]}>
                            <CustomStepLabel handleClick={handleStepClick} question={questions[6]} index={6} completed={completed} answers={answers} isActive={state === 6}/>
                            <CustomStepContent question={questions[6]} index={6} handleNext={handleNext} handleBack={handleBack}>
                                <CustomMoneyStepTextField index={6} answers={answers} onChange={setAnswers} {...answers[6]} onKeyPress={handleNext} helperText={errorTexts[6]}/>
                            </CustomStepContent>
                        </Step>
                        {canSubmit && 
                            <Box className='submit-final-step'>
                                <Typography sx={{paddingBottom: '20px'}}>¡Todo completado! Confirma que tus respuestas sean las correctas y después da click en CALCULAR para hacer la cotización de tu seguro de vida</Typography>
                                <Button onClick={handleSubmit} className='submit-btn'>
                                    calcular
                                </Button>
                            </Box>
                        }
                    </Stepper>
                </div>
                <SevenQuestionsQuotationGrid 
                    submitted={submitted} 
                    data={submittedData} 
                    handleReset={handleReset} 
                    sendEmail={handleSendEmail}
                    requestQuotation={handleRequestQuotation}
                />
            </Paper>
        </div>
    );
};

function ChildRow(props) {
    
    const {child, updateChild, index, deleteChild} = props;
    
    return(
        <TableRow>
            <TableCell>{index + 1}</TableCell>
            <TableCell>
                <TextField
                    size='small'
                    variant='outlined'
                    type='number'
                    value={child.age}
                    onChange={e => updateChild('age', e.target.value, index)}
                />
            </TableCell>
            <TableCell>
                <TextField
                    select
                    size='small'
                    variant='outlined'
                    value={child.schoolType}
                    onChange={e => updateChild('schoolType', e.target.value, index)}
                >
                    <MenuItem value='privada'>PRIVADA</MenuItem>
                    <MenuItem value='pública'>PÚBLICA</MenuItem>
                </TextField>
            </TableCell>
            <TableCell onClick={() => deleteChild(index)}>
                <IconButton>
                    <Delete />
                </IconButton>
            </TableCell>
        </TableRow>

    );
}

function CustomTextField(props) {
    const {index, answers, onChange, onKeyPress, ...other} = props;

    const answer = answers[index];

    return(
        <TextField
            autoFocus
            variant='outlined'
            onChange = {(e) => onChange({...answers, [index]: {...answer, value: e.target.value}})}
            onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  // Do code here
                  ev.preventDefault();
                  onKeyPress();
                }
            }}
            {...other}
        />
    );
}

function CustomMoneyStepTextField(props) {
    const {index, answers, onChange, onKeyPress, ...other} = props;

    const answer = answers[index];

    return(
        <CurrencyTextField
            autoFocus
            type='tel'
            variant='outlined'
            currencySymbol='$'
            onChange = {(e, value) => onChange({...answers, [index]: {...answer, value: value}})}
            {...other}
            onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  // Do code here
                  ev.preventDefault();
                  onKeyPress();
                }
            }}
        />
    );
}

function CustomStepLabel(props){
    
    const {question, index, handleClick, completed, answers, isActive} = props;

    const check = completed[index];
    const answer = answers[index];
    let value = index !== 4 && check 
    ? <Typography variant='caption'>
        {index === 1 ? answer.value : parseFloat(answer.value).toCurrencyString(2)}
    </Typography> 
    : null;

    if(index === 4 && check) {
        if(answer.children.length > 0){
            const pub = answer.children.filter(child => child.schoolType !== 'privada').length;
            const priv = answer.children.filter(child => child.schoolType === 'privada').length;
            value = <Box>
                <Typography variant='caption'>{pub} en Pública</Typography>
                <Typography variant='caption'>{priv} en Privada</Typography>
            </Box>
        } else {
            value = <Typography variant='caption'>N/A</Typography>
        }
    }

    return(
        <StepButton
            className={'step-btn' + (isActive ? ' active' : '')}
            onClick={() => handleClick(index)}
            sx={{
                '& .MuiStepIcon-root':{
                    color: question.color,
                },
            }}
            optional={value}
        >
            {question.question}
        </StepButton>
    )
}

function CustomStepContent(props) {    
    const {question, index, handleNext, handleBack} = props;
    return(
        <StepContent>
            <Typography>{question.description}</Typography>
            <Box>
                <div className='content-body'>
                    {props.children}
                </div>
                <div className='nav-btns'>
                    <Button
                        disabled={index === 0}
                        onClick={handleBack}
                    >
                        Regresar
                    </Button>
                    <Button
                        sx={{
                            backgroundColor: question.color,
                            '&:hover': {
                                backgroundColor: addColor(question.color, 'rgb(0, 0, 0)', 0.1),
                            }
                        }}
                        variant="contained"
                        onClick={handleNext}
                    >
                        {index === 6 ? 'Finalizar' : 'Siguiente'}
                    </Button>
                </div>
            </Box>
        </StepContent>
    );
}

export default SevenQuestionsView;