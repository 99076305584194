import { LinkedIn, Instagram } from '@mui/icons-material'
import React from 'react'

const teamMembers = {
    one: {
        name: 'Douglas B. Woolfolk',
        img: 'https://media.licdn.com/dms/image/C5603AQH2yE5FT65KhA/profile-displayphoto-shrink_800_800/0/1555091092555?e=1677715200&v=beta&t=ULZs_qRXVkj0jPheuwPdc8Vqw58JYurtoINu8p5Ts88',
        title: 'Fundador / Experto en Seguros',
        socialMedia: {
            linkedIn: {
                icon: <LinkedIn/>,
                link: 'https://www.linkedin.com/in/douglas-woolfolk-7ba2107b/',
            },
            instagram: {
                icon: <Instagram/>,
                link: 'https://www.instagram.com/cofinza_oficial/?hl=es-la',
            }
        }
    },
    two: {
        name: 'Ing. Sebastian Woolfolk',
        img: 'https://media.licdn.com/dms/image/C4E03AQH8OlMfQgx7UQ/profile-displayphoto-shrink_800_800/0/1563220723332?e=1677715200&v=beta&t=M_N2WDyeQD2fTmMSzNnOdjl15NwUjpCRlsjM_VEM-9Y',
        title: 'Agente de Seguros / Ing. en Software',
        socialMedia: {
            linkedIn: {
                icon: <LinkedIn/>,
                link: 'https://www.linkedin.com/in/swoolfolk/',
            },
        }
    }, 
    three: {
        name: 'N/A',
        img: 'https://www.arsvitalis.es/wp-content/uploads/2018/12/blank-profile-picture-973460_1280.png',
        title: 'Desarrollador de Negocios',
        socialMedia: {
        }
    }
}

function TeamView() {
    return (
        <div className='team-view'>
            <div className='banner'>
                <h1>Nuestro Equipo</h1>
                <h2>Agentes, Desarrolladores, Protectores</h2>
                <p>Los <span className='bold' style={{marginRight: '8px'}}>Magos</span> detras de la cortina.</p>
            </div>
            <div className='team-body'>
                <p>No es un trabajo fácil armar el mejor equipo posible. Aquí en COFINZA tratamos de crear la atmósfera perfecta entre un trabajo duro y la mejor moral posible.</p>
                <div className='the-team'>
                    <h1>El Equipo</h1>
                    <div className='underline'/>
                    <p>Founded por un experto en seguros, Cofinza nacio con la idea de traer a México a otro nivel en seguros, utilizando tecnologías <span className='bold'>InsureTech</span>, la industria no sera igual. Combinamos el conocimiento de nuestro equipo en <span className='bold'>Seguros</span> y <span className='bold'>Desarrollo de Software</span>.</p>
                    <div className='team-pictures'>
                        <div className='cross-line'/>
                        { 
                            Object.entries(teamMembers).map(([key, member]) => (
                                <div className={`member ${key}`} key={key}>
                                    <img src={member.img} alt=''/>
                                    <h1>{member.name}</h1>
                                    <h2>{member.title}</h2>
                                    <div className='social-media'>
                                        {
                                            Object.values(member.socialMedia).map((media, index) => (
                                                <div key={index}>
                                                    <a href={media.link} target='_blank' rel="noreferrer">{media.icon}</a>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TeamView;