import { Email, Facebook, Instagram, LocationOn, Phone } from '@mui/icons-material';
import { Box, IconButton, List, ListItemButton, ListItemIcon, ListItemText, TextField } from '@mui/material';
import React, { useState } from 'react'
import { createRipples } from '../../styles/buttons';
import { postToBack } from '../../utils/back';
import './ContactView.css';

function ContactView(props) {
        
    const {createNewMessage} = props;

    const [data, setData] = useState({
        name: '',
        email: '',
        message: '',
    });

    const [errors, setErrors] = useState({});

    const verifyErrors = () => {
        const newErrors = {};

        if(data.name === '') newErrors.name = 'No puede estar vacio';
        if(data.email === '') newErrors.email = 'No puede estar vacio';
        if(data.message === '') newErrors.message = 'No puede estar vacio';

        return newErrors;
    }

    const handleSend = async (e) => {
        createRipples(e);

        const newErrors = verifyErrors();
        if(Object.values(newErrors).length === 0) {
            const response = await postToBack('/contact/', {values: data});
            if(response.status === 200){
                setData({
                    name: '',
                    email: '',
                    message: '',
                });
                createNewMessage('Mensaje Enviado', 'success')
            }
        }

        setErrors(newErrors);
    }

    const handleDataChange = (key, value) => {
        setData({...data, [key]: value});
        setErrors({...errors, [key]: undefined});
    }

    return (
        <div className='contact-view'>
            <div className='background one' />
            <div className='background two' />
            <div className='contact-card'>
                <Box className='social-media'>
                    <IconButton href='https://instagram.com/cofinza_oficial?igshid=NTdlMDg3MTY=' target='_blank'>
                        <Instagram />
                    </IconButton>
                    <IconButton href='https://www.facebook.com/cofinza' target='_blank'>
                        <Facebook />
                    </IconButton>
                </Box>
                <Box className='card-body'>
                    <h1>Ponte en contacto</h1>
                    <p>Ingresa tus datos para que un agente se ponga en contacto con usted.</p>
                    <CustomTextField 
                        value={data.name}
                        placeholder='Nombre'
                        helperText={errors.name}
                        error={Boolean(errors.name)}
                        onChange={v => handleDataChange('name', v)}
                        />
                    <CustomTextField 
                        value={data.email}
                        placeholder='Correo'
                        helperText={errors.email}
                        error={Boolean(errors.email)}
                        onChange={v => handleDataChange('email', v)}
                        />
                    <CustomTextField 
                        value={data.message}
                        helperText={errors.message}
                        error={Boolean(errors.message)}
                        placeholder='Escríbenos tu mensaje...'
                        multiline={true}
                        rows={8}
                        onChange={v => handleDataChange('message', v)}
                    />
                    <Box className='send-btn' variant='contained' onClick={handleSend} >Enviar</Box>
                </Box>
                <Box className='card-side'>
                    <img className='card-side-img' src='https://static.vecteezy.com/system/resources/previews/004/491/054/non_2x/web-design-concept-for-web-banner-man-creates-site-layout-places-graphic-elements-and-navigation-buttons-page-modern-person-scene-illustration-in-flat-cartoon-design-with-people-characters-vector.jpg' alt='' />
                    <List>
                        <ListItemButton>
                            <ListItemIcon className='card-icon'><Phone /></ListItemIcon>
                            <ListItemText>+52 (33) 1394 9817</ListItemText>
                        </ListItemButton>
                        <ListItemButton>
                            <ListItemIcon className='card-icon'><Email /></ListItemIcon>
                            <ListItemText>swoolfolk@cofinza.mx</ListItemText>
                        </ListItemButton>
                        <ListItemButton href='https://www.google.com/maps/place/COFINZA+seguros+ASESORES/@20.6896811,-103.417832,17z/data=!3m1!4b1!4m5!3m4!1s0x8428afd4b1f21d13:0x14c04f2e0b84612e!8m2!3d20.6896811!4d-103.4156433' target='_blank'>
                            <ListItemIcon className='card-icon'><LocationOn /></ListItemIcon>
                            <ListItemText>Torre MetLife, Av. Patria 888-piso 6, Jardines Universidad, 45110 Zapopan, Jal. Mexico</ListItemText>
                        </ListItemButton>
                    </List>
                </Box>
            </div>
        </div>
    )
}

function CustomTextField(props){
    const {onChange, ...other} = props;
 return(
    <TextField 
        fullWidth
        onChange={e => onChange(e.target.value)}
        {...other}
    />
 )

}

export default ContactView;