import React, { useState } from 'react'
import './TopBar.css';
import { NavLink, useLocation } from 'react-router-dom';
import { Box, Collapse, Divider, Drawer, IconButton, List, ListItemButton, ListItemText, Menu as TopMenu } from '@mui/material';
import { ArrowDropDownRounded, Menu } from '@mui/icons-material';
import {createRipples} from './../../styles/buttons';
import { getFromGoogleDrive } from '../../utils/images';
import { logo } from '../../assets/images';

const options = {
    clients: {
        name: 'Cliente',
        subOptions: [
            {
                name: 'Seven Questions',
                route: '/SevenQuestions/',
            },
            {
                name: 'Productos',
                route: '/Products/',
            },
            // {
            //     name: 'Tips de Finanzas',
            //     route: '/Tips/Finances/',
            // },
            {
                name: 'Portal de Pagos',
                link: 'https://www.adquiramexico.com.mx/m2mask/metlife',
            },
            {
                name: 'Directorio de Servicios Medicos',
                link: 'https://www.metlife.com.mx/servicios/directorio-medico/',
            },
            {
                name: 'Programa una Cirugía',
                link: 'https://www.metlife.com.mx/soy-cliente/tramites-y-servicios/programar-cirugia/',
            },
            {
                name: 'Solicitar un Reembolso',
                link: 'https://www.metlife.com.mx/soy-cliente/tramites-y-servicios/reembolso-en-linea/',
            },
            {
                name: 'Contáctanos',
                route: '/Contact/'
            },
        ]
    },
    agents: {
        name: 'Agente',
        subOptions: [
            {
                name: 'Seven Questions',
                route: '/SevenQuestions/'
            },
            // {
            //     name: 'Tips de Ventas',
            //     route: '/Tips/Sales/'
            // },
            {
                name: 'Portal de Agentes',
                link: 'https://servicios.metlife.com.mx/wps/portal/agentes',
            },
            {
                name: 'Plataforma DAP',
                link: 'https://metlifedap.niit-mts.com/mx/',
            },
        ]
    },
};

function TopBar() {

    const location = useLocation();

    const pathName = location.pathname;

    const width = window.innerWidth;

    const isMobile = width < 1250;

    const [state, setState] = useState(false)

    const toggleDrawer = (open) => {
        setState(open);
        setOpenedMenu(null);
    }

    const handleTopClick = (e, key) => {
        createRipples(e);
        setOpenedMenu(key);
        if(e !== null){
            setAnchorEl(e.currentTarget);
        }
    }
    const handleCloseTop = () => {
        setOpenedMenu(null);
        setAnchorEl(null);
    }

    const handleDrawerOptionClick = (key) => {
        if(openedMenu === key) setOpenedMenu(null);
        else setOpenedMenu(key);
    }

    const [openedMenu, setOpenedMenu] = useState(null);
    
    const [anchorEl, setAnchorEl] = useState(null);

    return (
        <div className='top-bar'>
            <div>
            
                <a href='/'><img src={logo} alt='logo' /></a>
            </div>
            {
                !isMobile ? <div className={'links' + (pathName === '/' ? ' home-selected' : '')}>
                    {
                        Object.entries(options).map(option => (
                            <TopBarBtn key={option[0]} 
                                option={option} 
                                handleClick={e => handleTopClick(e, option[0])} 
                                opened={openedMenu}
                                handleCloseTop={handleCloseTop}
                                anchor={anchorEl}
                            />
                        ))
                    }
                    {/* <NavLink className='option' to='/About/'>Sobre Nosotros</NavLink> */}
                    <NavLink className='option' to='/Contact/'>Contáctanos</NavLink>
                </div>
                : <IconButton className='top-menu-btn' onClick={e => toggleDrawer(true)}>
                    <Menu/>
                </IconButton>
            }
            
            <Drawer
                anchor={'right'}
                open={state}
                onClose={() => toggleDrawer(false)}
                
            >
                <Box
                    role='presentation'
                    className='top-bar-drawer'
                >
                    <List>
                        {
                            Object.entries(options).map(([key, option]) => (
                                <Box key={key}>
                                    <ListItemButton onClick={ () => handleDrawerOptionClick(key)} selected={openedMenu === key} >
                                        <ListItemText>{option.name}</ListItemText>
                                        <ArrowDropDownRounded />
                                    </ListItemButton>
                                    <Collapse in={openedMenu === key} unmountOnExit>
                                        <List component='div' disablePadding className='sub-options-menu'>
                                            {
                                                option.subOptions.map(subOption => (
                                                    <ListItemButton
                                                        key={subOption.name}
                                                        href={subOption.route ? subOption.route : subOption.link}
                                                        target={subOption.route ? '_self' : '_blank'}
                                                    >
                                                        <ListItemText>{subOption.name}</ListItemText>
                                                    </ListItemButton>
                                                ))
                                            }
                                        </List>
                                    </Collapse>
                                </Box>
                            ))
                            
                        }
                        {/*<ListItemButton href='/About/' target='_self'>
                            <ListItemText>Sobre Nosotros</ListItemText>
                    </ListItemButton>*/}
                        <ListItemButton href='/Contact/' target='_self'>
                            <ListItemText>Contáctanos</ListItemText>
                        </ListItemButton>
                    </List>
                </Box>
            </Drawer>
        </div>
    );
}

function TopBarBtn(props){

    const {option, handleClick, opened, anchor, handleCloseTop} = props;

    const open = opened === option[0];

    const {name} = option[1];



    return (
        <Box>
            <Box className={'option' + (open ? ' opened' : '')} onClick={handleClick}>
                {name} <ArrowDropDownRounded />
            </Box>
            <TopMenu
                open={open}
                anchorEl={anchor}
                onClose={handleCloseTop}
                anchorOrigin={{
                    vertical:'bottom',
                    horizontal: 'center'
                }}
                sx={{
                    marginTop: '5px',
                    '& .MuiPaper-root': {
                        transform: 'translate(-50%, 0) !important',
                    }
                }}
                >
                <Box className='top-menu'>
                    <h1>{name}</h1>
                    <Divider orientation='vertical' flexItem />
                    <List>
                        {
                            option[1].subOptions.map(subOption => (
                                <ListItemButton key={subOption.name} 
                                    href={subOption.route ? subOption.route : subOption.link}
                                    target={subOption.route ? '_self' : '_blank'}
                                >
                                    <ListItemText>{subOption.name}</ListItemText>
                                </ListItemButton>
                            ))
                        }
                    </List>
                </Box>
            </TopMenu>
        </Box>
    );

}

export default TopBar;