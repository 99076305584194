import {React} from 'react'
import { createRipples } from '../../styles/buttons';

import './Home.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import HomeBackground from './HomeBackground';
import { useNavigate } from 'react-router-dom';
import Carrousel from '../../components/carrousel/Carrousel';
import { Box, Divider, List, ListItem, ListItemIcon, ListItemText, ListSubheader } from '@mui/material';
import { AttachMoney, Circle, FlightTakeoff, Handshake, Public, RocketLaunch, VolunteerActivism } from '@mui/icons-material';
import AgentBelt from './AgentBelt';

const skills = [
    [
        {
            name: 'Impacto en la sociedad',
            description: 'Nuestra labor ayuda a tener  una vida con finanzas sanas.',
            icon: <Handshake />,
        },
        {
            name: 'Ingresos Atractivos',
            description: 'Altos ingresos, al ser una de las carreras mejor pagadas en México.',
            icon: <AttachMoney />,
        },
        {
            name: 'Tu vida a otro nivel',
            description: 'Asistir a seminarios, convenciones y viajes con reconocimiento internacional.',
            icon: <FlightTakeoff />,
        },
    ],
    [
        {
            name: 'Ser un emprendedor',
            description: 'Estamos creando a los nuevos campeones y mejores agentes de la industria.',
            icon: <RocketLaunch />,
        },
        {
            name: 'Lider Mundial en Seguros',
            description: 'Respaldo, metodología y plataformas únicas en el sector.',
            icon: <Public />,
        },
        {
            name: 'Trabajar con propósito',
            description: 'Cambiamos de un estilo de vida, a una vida con estilo, con base a nuestra pasión y compromiso por lo que hacemos.',
            icon: <VolunteerActivism />,
        },
    ],
];

const partnerData = [
    {
        name: null,
        children: [
            'MetLife, Inc.  con más de 150 años de historia',
            'Presencia en más de 45 países',
            'Lideres de la industria de seguros de vida',
        ],
    },
    {
        name: 'En México',
        children: [
            'Más de 10 millones de asegurados',
            'Calificación financiera MX-AAA',
            '#1 En seguros de personas en el país',
            'Fundación MetLife',
            'Empresa socialmente responsable',
        ]
    }
]

const agentData = [
    {
        image: 'https://media.licdn.com/dms/image/C5603AQH2yE5FT65KhA/profile-displayphoto-shrink_800_800/0/1555091092555?e=1677715200&v=beta&t=ULZs_qRXVkj0jPheuwPdc8Vqw58JYurtoINu8p5Ts88',
        name: 'Douglas Woolfolk',
        description: 'Director General',
        quote: 'Insertar Cita',
    },
    {
        image: 'https://media.licdn.com/dms/image/C4E03AQH8OlMfQgx7UQ/profile-displayphoto-shrink_800_800/0/1563220723332?e=1677715200&v=beta&t=M_N2WDyeQD2fTmMSzNnOdjl15NwUjpCRlsjM_VEM-9Y',
        name: 'Sebastian Woolfolk',
        description: 'Agente / Ing. Software',
        quote: 'Insertar Cita',
    },
    {
        image: 'https://static.wixstatic.com/media/939629_8afcd2a5947b4caaba929b7f0762b9fe~mv2.jpg/v1/fill/w_400,h_400,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/IMG_0391_resized_JPG.jpg',
        name: 'Martin Martinez',
        description: 'Agente desde 2022',
        quote: 'Desde que inicie como asesor de seguros de vida me puedo dedicar a mi familia.',
    },
    {
        image: 'https://static.wixstatic.com/media/939629_2d8dc92c24104014b46fc8e232f6fdb0~mv2.jpg/v1/crop/x_0,y_476,w_1944,h_1964/fill/w_198,h_200,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/IMG_0417_resized_JPG.jpg',
        name: 'Jazz Cervantes',
        description: 'Agente desde 2022',
        quote: 'Trabajo tranquila sabiendo que mi seguro va a cubrir cualquier necesidad de mi familia.',
    },
    {
        image: 'https://static.wixstatic.com/media/939629_8cb08f13973f47879d9c9f445581497c~mv2.jpg/v1/fill/w_200,h_200,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/IMG_0375_resized_JPG.jpg',
        name: 'Anwar Montes',
        description: 'Agente desde 2022',
        quote: 'Conozco a mis clientes y hoy son mis amigos y parte de mi vida.',
    },
    {
        image: 'https://media.licdn.com/dms/image/C5603AQH2yE5FT65KhA/profile-displayphoto-shrink_800_800/0/1555091092555?e=1677715200&v=beta&t=ULZs_qRXVkj0jPheuwPdc8Vqw58JYurtoINu8p5Ts88',
        name: 'Douglas Woolfolk',
        description: 'Director General',
        quote: 'Insertar Cita',
    },
    {
        image: 'https://media.licdn.com/dms/image/C4E03AQH8OlMfQgx7UQ/profile-displayphoto-shrink_800_800/0/1563220723332?e=1677715200&v=beta&t=M_N2WDyeQD2fTmMSzNnOdjl15NwUjpCRlsjM_VEM-9Y',
        name: 'Sebastian Woolfolk',
        description: 'Agente / Ing. Software',
        quote: 'Insertar Cita',
    },
    {
        image: 'https://static.wixstatic.com/media/939629_8afcd2a5947b4caaba929b7f0762b9fe~mv2.jpg/v1/fill/w_400,h_400,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/IMG_0391_resized_JPG.jpg',
        name: 'Martin Martinez',
        description: 'Agente desde 2022',
        quote: 'Desde que inicie como asesor de seguros de vida me puedo dedicar a mi familia.',
    },
    {
        image: 'https://static.wixstatic.com/media/939629_2d8dc92c24104014b46fc8e232f6fdb0~mv2.jpg/v1/crop/x_0,y_476,w_1944,h_1964/fill/w_198,h_200,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/IMG_0417_resized_JPG.jpg',
        name: 'Jazz Cervantes',
        description: 'Agente desde 2022',
        quote: 'Trabajo tranquila sabiendo que mi seguro va a cubrir cualquier necesidad de mi familia.',
    },
    {
        image: 'https://static.wixstatic.com/media/939629_8cb08f13973f47879d9c9f445581497c~mv2.jpg/v1/fill/w_200,h_200,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/IMG_0375_resized_JPG.jpg',
        name: 'Anwar Montes',
        description: 'Agente desde 2022',
        quote: 'Conozco a mis clientes y hoy son mis amigos y parte de mi vida.',
    },
]

function Home() {

    const navigate = useNavigate();

    const handleKnowMoreBtn = (e, key) => {
        createRipples(e);
        navigate('/SevenQuestions');
    }

    return (
        <div className='home'>
            <div className='home-banner'>
            <HomeBackground />
                <Carrousel>
                    <h1>7 Questions</h1>
                    <h1>Facil y Rápido</h1>
                    <h1>Menos de 10 Minutos</h1>
                </Carrousel>
                <p>Prueba nuestro nuevo sistema de 7 Preguntas, donde podras saber cual es tu suma asegurada ideal para un seguro de vida.</p>
                <div className='know-more-btn' onClick={(e) => handleKnowMoreBtn(e)}>
                    <p>Probar ahora</p>
                </div>
            </div>
            <Box className='home-skills'>
                <Box className='sub-title'>
                    <h2>¿qué te podemos ayudar a lograr?</h2>
                    <h1>Nuestra oferta</h1>
                    <p>Somos agentes especializados en protección de<br />las finanzas personales y con tu trabajo podrás lograr</p>
                </Box>
                <Box className='skills-table'>
                    {
                        skills.map((row, index) => (
                            <Box key={index} className='skill-row'>
                                {
                                    row.map(({name, description, icon}) => (
                                        <Box key={name} className='skill'>
                                            <Box className='skill-icon'>{icon}</Box>
                                            <h2>{name}</h2>
                                            <p>{description}</p>
                                        </Box>
                                    ))
                                }
                            </Box>
                        ))
                    }
                </Box>
            </Box>
            <Box className='home-partner'>
                <Box className='partner-background' />
                <Box className='sub-title'>
                    <h2>¿quién nos respalda?</h2>
                    <h1>Nuestro socio comercial</h1>
                </Box>
                <Box className='partner-card'>
                    <Box className='partner-logo'>
                        <img src='https://companieslogo.com/img/orig/MET-d9ef9e6b.png?t=1648156508' alt='' />
                        <h1>MetLife</h1>
                    </Box>
                    <Box className='partner-info'>
                        <List>
                            {
                                partnerData.map(({name, children}, index) => (
                                    <Box key={index}>
                                        {name !== null && <Divider />}
                                        {name !== null && <ListSubheader>{name}</ListSubheader>}
                                        {
                                            children.map(point => (
                                                <ListItem key={point}>
                                                    <ListItemText>{point}</ListItemText>
                                                    <ListItemIcon><Circle/></ListItemIcon>
                                                </ListItem>
                                            ))
                                        }
                                    </Box>
                                ))
                            }
                            
                        </List>
                    </Box>
                </Box>
            </Box>
            <Box className='agent-belt'>
                <Box className='background one'/>
                <Box className='background two'/>
                <Box className='background three'/>
                <Box className='sub-title'>
                    <h1>Nuestros Partners</h1>
                    <p>Dedicados a la protección de nuestros clientes</p>
                </Box>
                <AgentBelt agentData={agentData} />
            </Box>
        </div>
    );
};

export default Home;