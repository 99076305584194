/*eslint no-extend-native: ["error", { "exceptions": ["Number"] }]*/
/**
 * Returns a number whose value is limited to the given range.
 *
 * Example: limit the output of this computation to between 0 and 255
 * (x * 255).clamp(0, 255)
 *
 * @param {Number} min The lower boundary of the output range
 * @param {Number} max The upper boundary of the output range
 * @returns A number in the range [min, max]
 * @type Number
 */
Number.prototype.clamp = function(min, max) {
    return Math.min(Math.max(this, min), max);
};

Number.prototype.toCurrencyString = function(decimals) {

  if(this.toString() !== 'NaN'){
    return '$ ' + this.toFixed(decimals).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  } else {
    return;
  }
}
