import { CopyrightOutlined, EmailOutlined, Facebook, Instagram, PhoneOutlined } from '@mui/icons-material';
import React from 'react';
import { NavLink } from 'react-router-dom';

import './Footer.css';

const links = {
    home: {
        path: '/', 
        name: 'Inicio'
    },
    products: {
        path: '/Products', 
        name: 'Productos'
    },
    // about: {
    //     path: '/About', 
    //     name: 'Sobre Nosotros'
    // },
    contanct: {
        path: '/Contact', 
        name: 'Contacto'
    },
};

function Footer() {

    return (
        <div className='footer'>
            <div className='sub-sections'>
                <div className='sub-section'>
                    <div className='sub-section-line'/>
                    <h1>Menu</h1>
                    <div className='nav-links'>
                        {
                            Object.entries(links).map(([key, link]) => (
                                <NavLink key={key} to={link.path} className='nav-link'>{link.name}</NavLink>
                            ))
                        }
                    </div>
                </div>
                <div className='sub-section'>
                    <div className='sub-section-line'/>
                    <h1>Nuestros contactos</h1>
                    <div className='nav-links'>
                        <p><EmailOutlined/>swoolfolk@cofinza.mx</p>
                        <p><PhoneOutlined/>+52 (33) 1394 9817</p>
                        <a className='nav-link' href='https://www.google.com/maps/place/COFINZA+seguros+ASESORES/@20.6896811,-103.417832,17z/data=!3m1!4b1!4m5!3m4!1s0x8428afd4b1f21d13:0x14c04f2e0b84612e!8m2!3d20.6896811!4d-103.4156433' target='_blank' rel='noreferrer'>
                            Torre MetLife, Av. Patria 888-piso 6, Jardines Universidad, 45110 Zapopan, Jal. Mexico
                        </a>
                    </div>
                </div>
                <div className='sub-section'>
                    <div className='sub-section-line'/>
                    <h1>Redes</h1>
                    <div className='nav-links'>
                        <a href='https://instagram.com/cofinza_oficial?igshid=NTdlMDg3MTY=' className='nav-link social-media' target='_blank' rel='noreferrer'><Instagram/> @cofinza_oficial</a>
                        <a href='https://www.facebook.com/cofinza' className='nav-link social-media' target='_blank' rel='noreferrer'><Facebook/> Cofinza</a>
                    </div>
                </div>
            </div>
            <div className='footer-footer'>
                <p>Copyright<CopyrightOutlined/>2023. Cofinza. All Rights Reserverd</p>
            </div>
        </div>
    );
};

export default Footer;