import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Footer from '../components/footer/Footer';
import MessagesList from '../components/messages-list/MessagesList';
import TopBar from '../components/top-bar/TopBar';

import './MainView.css';

function MainView(props) {

  const {children} = props;

  const [messages, setMessages] = useState([
  ]);

  const [messageIndex, setMessageIndex] = useState(0);

  const handleCreateNewMessage = (message, type) => {
    setMessages([...messages, {message: message, type: type, timeLeft: 5000, index: messageIndex, top: messages.length * 80, left: 0}]);
    setMessageIndex(messageIndex+1);
  }

  return (
    <div className='main-view'>
        <TopBar />
        <MessagesList messages={messages} setMessages={setMessages}/>
        <Box className='main-view-view'>
          {
            React.Children.map(children, child => {
              if(React.isValidElement(child)) {
                return React.cloneElement(child, {createNewMessage: handleCreateNewMessage})
              }
              return child;
            })
          }
        </Box>
        <Footer />
    </div>
  );
}

export default MainView;