import { createBrowserRouter } from "react-router-dom";
import ErrorPage from "./views/error-page/ErrorPage";
import Home from './views/home/Home';
import App from "./App";
import AboutView from "./views/about/AboutView";
import ProjectsView from "./views/projects/ProjectsView";
import SevenQuestionsView from "./views/seven-questions/SevenQuestionsView";
import ContactView from "./views/contact/ContactView";
import ProductsView, { products } from "./views/products/ProductsView";
import SingleProductView from "./views/products/SingleProductView";

const router = createBrowserRouter([
    {
      path: '/',
      errorElement: <App><ErrorPage /></App>,
      element: <App><Home/></App>
    },
    {
      path: '/Projects',
      errorElement: <App><ErrorPage /></App>,
      element: <App><ProjectsView/></App>
    },
    {
      path: '/About',
      errorElement: <App><ErrorPage /></App>,
      element: <App><AboutView/></App>
    },
    {
      path: '/SevenQuestions',
      errorElement: <App><ErrorPage /></App>,
      element: <App><SevenQuestionsView/></App>
    },
    {
      path: '/Contact',
      errorElement: <App><ErrorPage /></App>,
      element: <App><ContactView/></App>
    },
    {
      path: '/Products',
      errorElement: <App><ErrorPage /></App>,
      children: 
      [
        ...Object.entries(products).map(([key, product]) => {
          return {
            path: `${product.name}`,
            element: <App><SingleProductView productKey={key} /></App>,
            errorElement: <App><ErrorPage /></App>,
          }}
        ),
        {
          path: '/Products/',
          element: <App><ProductsView/></App>,
          errorElement: <App><ErrorPage /></App>,
        }
      ]
    },
  ]);

export default router;