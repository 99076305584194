import { Box } from '@mui/material';
import React from 'react'

import './LoadingModal.css';

function LoadingModal(props) {

  const {obscure = true} = props; 

  return (
    <Box className={'loading-pop-up' + (!obscure ? ' not-obscure' : '')}>
        <div className='loading-container'>
            <div className="loading-circle">
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
            </div>
            <div className='loading-text'>
                <p>Cargando...</p>
            </div>
        </div>
    </Box>
  )
}

export default LoadingModal;