import React, { useState } from 'react'
import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { addColor, getColor } from '../../utils/colors';
import { AttachEmail, ContactMail, RestartAlt } from '@mui/icons-material';
import {createRipples} from './../../styles/buttons';
import SendEmailModal from './SendEmailModal';

const texts = [
    'Ingreso actual',
    'Deuda a pagar',
    'Ahorros',
    'Fondo universitario',
    'Fondo de emergencia',
    'Costo funerario',
    'Seguro vigente',
    'Suma asegurada ideal:',
    'Suma asegurada adicional para retiro',
];

function SevenQuestionsQuotationGrid(props) {
    const {submitted, data, handleReset, sendEmail, requestQuotation} = props;

    const values = Object.values(data);
    const [open, setOpen] = useState(false);
    const [openQuotation, setOpenQuotation] = useState(false);

    const handleSendEmail = async (personalData) => {
        const response = await sendEmail({...data, ...personalData});
        return response;
    }
    const handleRequestQuotation = async (personalData) => {
        const response = await requestQuotation({...data, ...personalData});
        return response;
    }

    return (
        <Box className={'quotation-grid' + (submitted ? ' active' : '')}>
            <Table className='quotation-table'>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Cantidad</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        values.map((value, index) => (
                            <TableRow key={index} sx={ index < values.length - 2 
                                ? {
                                    '&:nth-of-type(odd)': {
                                        backgroundColor: addColor(getColor('--question-one'), addColor(getColor('--question-seven') ,'rgb(0, 0, 0)', 0.1), index / (values.length - 3) )
                                    },
                                    '&:nth-of-type(even)': {
                                        backgroundColor: addColor(addColor(getColor('--question-one'), addColor(getColor('--question-seven') ,'rgb(0, 0, 0)', 0.1), index / (values.length - 3)), 'rgb(255, 255, 255)', 0.3),
                                    }
                                } : {
                                    backgroundColor: 'rgba(var(--boolian-blue-rgb), 0.5)',
                                }
                            }>
                                <TableCell sx={ index < values.length - 2 ? null : {textAlign:'end', paddingRight: '0'}}>{texts[index]}</TableCell>
                                <TableCell>{value.toString().toCurrency(2)}</TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
            <Box className='action-btns'>
                <div className='action-btn' onClick={(e) => {
                    createRipples(e);
                    handleReset();
                }}>
                    <RestartAlt/>
                    <p>Reiniciar</p>
                </div>
                <div className='action-btn' onClick={(e) => {
                    createRipples(e);
                    setOpen(true);
                }}>
                    <AttachEmail/>
                    <p>Enviar por correo</p>
                </div>
                <div className='action-btn' onClick={(e) => {
                    createRipples(e);
                    setOpenQuotation(true);
                }}>
                    <ContactMail/>
                    <p>Solicitar cotización</p>
                </div>
            </Box>
            <SendEmailModal open={open} close={() => setOpen(false)} sendData={handleSendEmail}/>
            <SendEmailModal open={openQuotation} close={() => setOpenQuotation(false)} sendData={handleRequestQuotation}/>
        </Box>
    )
}

export default SevenQuestionsQuotationGrid;