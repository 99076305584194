import { Box, Button, InputAdornment, Modal, TextField } from '@mui/material';
import React, { useState } from 'react'
import LoadingModal from '../../components/loading-modal/LoadingModal';

function SendEmailModal(props) {

    const {open, close, sendData} = props; 

    const [data, setData] = useState({
        name: '', 
        email: '',
        phone: '',
    });
    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState({});

    const handleClose = () => {
        setData({
            name: '', 
            email: '',
            phone: '',
        });
        close();
    };

    const verifyErrors = () => {
        const newErrors = {};

        if(data.name.length === 0) newErrors['name'] = 'No debe estar vacio';
        if(data.email.length === 0) newErrors['email'] = 'No debe estar vacio';

        return newErrors;
    }

    const handleSendData = async () => {
        setIsLoading(true);

        const newErrors = verifyErrors();

        if(Object.values(newErrors).length === 0){
            const response = await sendData(data);
            if(response.status === 200){
                handleClose();
            } else {
                newErrors.server = 'Hubo un error al mandar el correo';
            }
        }
        
        setErrors(newErrors);



        setIsLoading(false);
    }

    return (
        <Modal open={open} onClose={handleClose}>
            <Box id='send-email-modal'>
                <h1>Ingresa tus datos</h1>
                <p>Te enviaremos la información que ingresaste junto con la tabla de valores</p>
                <CustomTextField 
                    required
                    value={data.name}
                    error={Boolean(errors.name)}
                    helperText={errors.name}
                    onChange={v => setData({...data, name: v})}
                    label='Nombre*'
                    />
                <CustomTextField 
                    required
                    value={data.email}
                    error={Boolean(errors.email)}
                    helperText={errors.email}
                    onChange={v => setData({...data, email: v})}
                    label='Correo*'
                />
                <CustomTextField 
                    value={data.phone}
                    onChange={v => setData({...data, phone: v})}
                    label='Teléfono'
                />
                <p className='error-text'>{errors.server}</p>
                <Box className='btns'>
                    <Button sx={{color: 'gray'}} onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button onClick={handleSendData}>
                        Enviar
                    </Button>
                </Box>
                {isLoading && <LoadingModal />}
            </Box>
        </Modal>
    )
}

function CustomTextField(props){

    const {onChange, label, ...other} = props;

    return(
        <TextField
            type='text'
            className='send-email-text-field'
            onChange={e => onChange(e.target.value)}
            InputProps={{startAdornment: <InputAdornment sx={{width: '100px', display:'flex', justifyContent:'space-between'}} position='start'>
                    <span>{label}</span><span>:</span>
                </InputAdornment>}}
            {...other}
        />
    );
}

export default SendEmailModal;