import React from 'react'

function AboutStoryView() {
  return (
    <div className='about-story-view'>
        <div className='banner'>
            <h1>COFINZA</h1>
            <h2>by Woolfolk</h2>
            <p>Una vida <span className='bold'>Co</span>n<span className='bold'>Fin</span>an<span style={{margin: 0}} className='bold'>za</span>s sanas</p>
        </div>
    </div>
  )
}

export default AboutStoryView;