const ADDRESS = 'ec2-18-220-116-77.us-east-2.compute.amazonaws.com';
const PORT = 8080;

const postRequestOptions = {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
}

export async function postToBack(address, values){
    const url = `http://${ADDRESS}:${PORT}${address}`;

    const json = JSON.stringify(values);

    const requestOptions = {
        ...postRequestOptions,
        body: json,
    };

    const response = await fetch(url, requestOptions);
    return response;
}

export const getFromBack = async (address, values = {}) => {
    let url = `http://${ADDRESS}:${PORT}${address}`;

    Object.entries(values).forEach(([key, value], index) => {
        if(index === 0){
            url+=`?${key}=${value}`;
        }else{
            url+=`&${key}=${value}`;
        }
    });

    const response = await fetch(url);

    return response;
} 
