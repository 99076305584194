import './App.css';
import MainView from './views/MainView';

function App(props) {

  const {children} = props;

  return (
    <div className="App" style={{height: window.innerHeight}}>
      <MainView>
        {children}
      </MainView>
    </div>
  );
}

export default App;
