import React, { useState } from 'react';

import { createRipples } from './../../styles/buttons';
import { getColor } from './../../utils/colors';

import './AboutView.css';
import FAQView from './faq/FAQView';
import AboutStoryView from './story/AboutStoryView';
import TeamView from './team/TeamView';
import WayView from './way/WayView';

const sections = {
    story: {
        name: 'Historia',
        view: <AboutStoryView/>,
        color: getColor('--main'),
    },
    team: {
        name: 'Equipo',
        view: <TeamView />,
        color: getColor('--boolian-green'),
    },
    way: {
        name: 'Misión',
        view: <WayView/>,
        color: getColor('--boolian-purple'),
    },
    faq: {
        name: 'FAQ',
        view: <FAQView />,
        color: getColor('--boolian-lightblue'),
    },
};

const getNewArrowValues = (button, parent) => {
    const width = button.offsetWidth;
    const pos = button.getBoundingClientRect();
    
    const parentPos = parent.getBoundingClientRect();
    const x = pos.x - parentPos.x + (width / 2);
    
    return [x];
};

function AboutView() {

    const [selectedSection, setSelectedSection] = useState('story');

    const handleClickOnTopBarBtn = (e, key) => {
        createRipples(e);
        setSelectedSection(key);

        const target = e.currentTarget;
        const parent = e.target.parentElement;

        const [x] = getNewArrowValues(target, parent);
        const arrow = parent.getElementsByClassName('about-top-arrow')[0];
        arrow.style.left = (x - 25)+'px';
        arrow.style.borderBottomColor = sections[key].color;
    }

    return (
        <div className='about-view'>
            <div className='about-top'>
                <h1>Sobre Nosotros</h1>
                <div className='about-top-btns'>
                    <div className='about-top-arrow'/>
                    {
                        Object.entries(sections).map(([key, value]) => (
                            <div key={key} className={`about-top-btn ${key}` + (selectedSection === key ? ' selected' : '')} 
                                onClick={e => handleClickOnTopBarBtn(e, key)}
                            >
                                {value.name}
                            </div>
                        ))
                    }
                </div>
            </div>
            {
                Object.entries(sections).map(([key, {view}]) => {return(
                    
                    selectedSection === key && <div key={key} className='sub-section'>
                        {view}
                    </div>
                    
                )})
            }
        </div>
    );
}

export default AboutView;