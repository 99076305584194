import './buttons.css';

export const createRipples = (event, target = null) => {

    if(event === null) return;

    const button = target === null ? event.currentTarget : target;
    const circle = document.createElement("span");
    
    const size = button.offsetWidth;
    const pos = button.getBoundingClientRect();
    const x = event.clientX - pos.x - (size / 2);
    const y = event.clientY - pos.y - (size / 2);
    
    circle.style.height = size+'px';
    circle.style.width = size + 'px';
    circle.style.left = x + 'px';
    circle.style.top = y + 'px';
    circle.classList.add("ripple"); 

    const ripple = button.getElementsByClassName("ripple")[0];

    if (ripple) {
        ripple.remove();
    }

    button.appendChild(circle);
};
