import { Box } from '@mui/material'
import React, { useEffect } from 'react';

import './MessagesList.css'
export const MESSAGE_TYPES = {
  alert: {
    name: '¡Alerta!',
  },
  info: {
    name: 'Información!',
  },
  error: {
    name: '¡Error!',
  },
  success: {
    name: '¡Éxito!',
  },
}
function MessagesList(props) {

  const {messages, setMessages} = props;


  const deleteMessage = (index) => {
    const newMessages = [...messages].map(message => {

      const values = {
        top: message.top,
        left: message.left,
      };

      if(message.index === index) {
        values.top = 0;
        values.left = '-100%';
        values.opacity = 0;
      } else {
        values.top = message.top - 80;
      }

      return({
        ...message,
        ...values,
      });
    });
    setMessages(newMessages);
    setTimeout(() => {
      setMessages(newMessages.filter(message => index !== message.index));
    }, 1000);
  }

  
  useEffect(() => {
    if(messages.length > 0){
      const interval = setInterval(() => {
        deleteMessage(messages[0].index);
      }, 5000);
    
      return () => {
        if(interval){
            clearInterval(interval);
        }
      }
    }
  })
  
  
  return (  
    <Box id='boolian-messages-list'>
      <Box className='boolian-messages-container'>
        {
          messages.map((message, index) => {
            return(
              <Message key={message.index} message={message} index={index} deleteMessage={deleteMessage}/>
            )
          })
        }
      </Box>
    </Box>
  )
}

function Message(props){

  const {message} = props;
  const {type} = message;

  const {name} = MESSAGE_TYPES[type];

  const top = message.top;
  const left = message.left;
  const opacity = message.opacity !== undefined ? 0 : 1;

  return (
    <Box className={'boolian-message ' + type} style={{top: top+'px', left: left, opacity:opacity}}>
      <Box className={'boolian-message-border'}/>
      <Box className='boolian-message-body'>
        <h1>{name}</h1>
        <p>{message.message}</p>
      </Box>
    </Box>
  )


}

export default MessagesList