import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react'

function AgentBelt(props) {
    const {agentData} = props;

    const handleNext = () => {
        const newPartners = [...partners];
        const [first] = newPartners.splice(0, 1);
        newPartners.push(first);
        setPartners(newPartners);
    }

    useEffect(() => {
        const interval = setInterval(() => {
          handleNext();
        }, 5000);
      
        return () => {
          if(interval){
              clearInterval(interval);
          }
        }
    });
    
    const [partners, setPartners] = useState([
        ...[...agentData].map((agent, index) => {agent.index = index; return agent}),
    ]);

    return (
        <Box className='belt-body'>
            {
                partners.map((agent, index) => (
                    <Box key={agent.index} sx={{transform: `translate(calc(${-50 * ((agentData.length / 2 +1) - index)}% - ${150 * ((agentData.length / 2) - index)}px), 0)`}} className={'agent-box' + (0 === (index - (agentData.length / 2)) ? ' centered' : '')}>
                        <img src={agent.image} alt='' />
                        <h1>{agent.name}</h1>
                        <h2>{agent.description}</h2>
                        <p>“{agent.quote}“</p>
                    </Box>
                ))
            }
        </Box>
    );
}

export default AgentBelt