import { Circle, NavigateNextRounded } from '@mui/icons-material';
import { Box, Breadcrumbs, Button, Divider, Link, List, ListItem, ListItemIcon, ListItemText, ListSubheader } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { getFromBack } from '../../utils/back';
import LoadingModal from '../../components/loading-modal/LoadingModal';
import ContactView from '../contact/ContactView';

function SingleProductView(props) {

    const {productKey, createNewMessage} = props;

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const [product, setProduct] = useState(null);

    useEffect(() => {
      const loadData = async () => {
        const response = await getFromBack('/products/', {productKey: productKey});

        if(response.status === 200) {
            const newProduct = await response.json();
            setIsLoading(false);
            setProduct(newProduct);
        } else {
            navigate('/');
        }
        
      }
      if(isLoading){
          loadData();
      }
    });
    
    if(isLoading){
        return(
            <Box className='single-product-view'>
                <LoadingModal />
            </Box>
        )
    } else {
        const breadcrumbs = [
            <Link underline='hover' key={0} href={'/'}>Inicio</Link>,
            <Link underline='hover' key={1} href={'/Products'}>Productos</Link>,
            <Link underline='hover' key={2} href={product.name}>{product.name}</Link>,
        ];
        return (
            <Box className='single-product-view'>
                <Box className='cofinza-gradient' />
                <Box>
                    <Breadcrumbs separator={<NavigateNextRounded fontSize='small' />}>
                        {breadcrumbs}
                    </Breadcrumbs>
                </Box>
                <Box className='product-body'>
                    <img src={product.image} alt='' />
                    <Box className='product-info'>
                        <h1>{product.name}</h1>
                        {
                            product.description.map((paragraph, index) => (
                                <p key={index}>{paragraph}</p>
                            ))
                        }
                        <List
                            disablePadding
                            subheader={<ListSubheader>Beneficios</ListSubheader>}
                        >
                            {
                                product.benefits.map(benefit => (
                                    <ListItem key={benefit}>
                                        <ListItemIcon><Circle /></ListItemIcon>
                                        <ListItemText>{benefit}</ListItemText>
                                    </ListItem>
                                ))
                            }
                        </List>
                        {   product.additionalCostCoverages.length > 0 && <Divider />}
                        {
                            product.additionalCostCoverages.length > 0 &&  <List
                                disablePadding
                                subheader={<ListSubheader>Coberturas con costo adicional</ListSubheader>}
                            >
                                {
                                    product.additionalCostCoverages.map(coverages => (
                                        <ListItem key={coverages}>
                                            <ListItemIcon><Circle /></ListItemIcon>
                                            <ListItemText>{coverages}</ListItemText>
                                        </ListItem>
                                    ))
                                }
                            </List>
                        }
                        <Divider />
                        <List
                            disablePadding
                            subheader={<ListSubheader>Especificaciones</ListSubheader>}
                        >
                            {
                                product.specs.map(spec => (
                                    <ListItem key={spec}>
                                        <ListItemIcon><Circle /></ListItemIcon>
                                        <ListItemText>{spec}</ListItemText>
                                    </ListItem>
                                ))
                            }
                        </List>
                        <br/>
                        {
                            product.broshure && <Button href={product.broshure} target='_blank' variant='contained'>Descarga el folleto de {product.name}</Button>
                        }
                    </Box>
                </Box>
                <ContactView createNewMessage={createNewMessage} />
            </Box>
        );
    }

}

export default SingleProductView;