import React from 'react'

function HomeBackground() {
  return (
    <div className='home-background'>
        <div className='background-rectangle'>
            
        </div>
    </div>
  )
}

export default HomeBackground