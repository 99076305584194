import React from 'react'

function WayView() {
  return (
    <div>
        <div className='way-view'>
            <div className='banner'>
                <h1>the cofinza way</h1>
                <h2>200% es nuestro mínimo</h2>
                <p>Con <span className='bold' style={{marginRight: '8px'}}>menos Papel</span> y con <span className='bold'>más Asegurados</span>.</p>
            </div>
        </div>
    </div>
  )
}

export default WayView;
