import React from 'react';

import './ProjectsView.css';
import {createRipples} from './../../styles/buttons';
import { Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const projects = {
    sevenQuestions: {
        img: 'https://cdn-icons-png.flaticon.com/512/4833/4833823.png',
        name: 'Seven Questions', 
        description: 'Request a quote for a life insurance with one of the best Insurance companies in the world.',
        link: '/SevenQuestions',
    },
    insuranceCRM: {
        img: 'https://cdn2.iconfinder.com/data/icons/programming-for-children/500/vab954_17_laptop_programming_isometric_cartoon_business_computer_hand-512.png',
        name: 'Insurance CRM', 
        description: 'Our very own CRM to manage from your activity pipeline, your policies wallet, contact information and even get your comissions in real time.',
        link: null,
    },
}


function ProjectsView() {

    const navigate = useNavigate();

    const handleClickOnMore = (e) => {
        createRipples(e);
    }
    
    const handleGoToBtn = (e, path) => {
        if(path === null) return;
        createRipples(e);
        navigate(path);
    }

    return (
        <div className='projects-view'>
            <div className='banner'>
                <div className='background bouncer one'/>
                <div className='background bouncer two'/>
                <img className='project-img' src='https://cdn2.iconfinder.com/data/icons/programming-for-children/500/vab954_17_laptop_programming_isometric_cartoon_business_computer_hand-512.png' alt='' />
                <div className='text'>
                    <h1>This is what we make</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent malesuada lacinia egestas.</p>
                    <div className='more-btn' onClick={handleClickOnMore}>Learn more</div>
                </div>
            </div>
            <div className='projects-body'>
                <h1>Services</h1>
                <Divider/>
                {
                    Object.entries(projects).map(([key, project]) => (
                        <div key={key}>
                            <div className='project'>
                                <div className='project-img'><img  src={project.img} alt='' /></div>
                                <div className='project-text'>
                                    <h2>{project.name}</h2>
                                    <p>{project.description}</p>
                                    <div className={'go-to-btn' + (project.link !== null ? '' : ' disabled')} onClick={(e) => handleGoToBtn(e, project.link)}>
                                        {project.link ? 'Go to Service' : 'Deshabilitado'}
                                    </div>
                                </div>
                            </div>
                            <Divider/>
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default ProjectsView;